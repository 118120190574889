<template>
  <v-card>
    <v-card-title>
      Plant Tree
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
      <v-alert
        :value="exportDisable"
        colored-border
        dismissible
        color="primary lighten-1"
        border="top"
        icon="mdi-check"
        transition="scale-transition"
        elevation="2"
        prominent
      >
        🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is
        completed 🏁, you will receive a notification via Slack under
        <code>#admindashboard</code> channel 📢.
      </v-alert>

      <v-btn color="primary" class="ml-3" @click="exportPlantTree()"
        >Export</v-btn
      >
    </v-container>

    <v-data-table
      :headers="headers"
      :items="cashouts"
      class="elevation-1"
      :search="search"
    >
    </v-data-table>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      cashouts: [],
      search: "",
      exportDisable:false,
      headers: [
        { text: "Title", value: "title", sortable: true },
        { text: "Currency", value: "currency", sortable: true },
        { text: "Amount", value: "amountInCurrency", sortable: true },
        { text: "Credited", value: "credited", sortable: true },
        { text: "Created At", value: "createdAt", sortable: false },
        // { text: "Action", value: "actions", sortable: false },
      ],
      };
  },

  mounted() {
    this.fetchPlantTree();
  },

  methods: {
    fetchPlantTree() {
      this.$setLoader();
      service.fetchPlantTree().then((res) => {
        this.cashouts = res.data;
        this.$disableLoader();
      });
    },

    exportPlantTree() {
      service.exportPlantTree();
      this.exportDisable = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
  },
};
</script>